import { ExamName } from 'modules/Exam'

export const getExamName = (domain: string): keyof typeof ExamName => {
  if (domain.includes('vpr.')) return 'vpr'
  if (domain.includes('oge.')) return 'oge'
  if (domain.includes('ege.')) return 'ege'
  if (domain.includes('gve.')) return 'gve'
  if (domain.includes('.by')) return 'ct'
  if (domain.includes('urok.')) return 'urok'
  if (domain.includes('skladunmt.')) return 'nmt'
  if (domain.includes('skladuzno.')) return 'zno'
  if (domain.includes('reshuolymp')) return 'olymp'
  if (domain.includes('gve.')) return 'gve'
  if (domain.includes('reshuent')) return 'ent'
  return 'ege'
}
