// import { ExamName } from 'modules/Exam'

type ExamType = { [key: string]: string }

export const getExamTitle = (name: string): string => {
  const Exam: ExamType = {
    ege: 'ЕГЭ',
    gve: 'ГВЭ',
    oge: 'ОГЭ',
    vpr: 'ВПР',
    ct: 'ЦТ и ЦЭ',
    urok: 'УРОК',
    nmt: 'НМТ',
    olymp: 'ОЛИМП',
    zno: 'ЗНО',
    ent: 'ЕНТ'
  }

  return Exam[name]
}
