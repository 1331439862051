import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import cx from 'classnames'

import { selectSubject } from 'modules/Subject'
import Fallback from 'components/Fallback'

import math from './assets/math.png'
import inf from './assets/inf.png'
import rus from './assets/rus.png'
import en from './assets/en.png'
import de from './assets/de.png'
import fr from './assets/fr.png'
import sp from './assets/sp.png'
import phys from './assets/phys.png'
import chem from './assets/chem.png'
import bio from './assets/bio.png'
import geo from './assets/geo.png'
import soc from './assets/soc.png'
import lit from './assets/lit.png'
import hist from './assets/hist.png'
import nat from './assets/nat.png'
import wh from './assets/wh.png'

interface ISubjectImageProps {
  className: string
}

const SubjectImage: FC<ISubjectImageProps> = ({ className }) => {
  const subject = useSelector(selectSubject)
  const subjectName =
    useMemo(() => subject?.name!.replace(/_ct/g, '').replace(/_ce/g, '').replace(/[\d_]/g, ''), [subject]) || 'math'
  const subjectLogoMap: any = Object.freeze({
    math,
    mathb: math,
    matho: math,
    mathp: math,
    ml: math,
    alg: math,
    geom: math,
    analys: math,
    exam: math,
    planimetry: math,
    algebra: math,
    stereometry: math,
    probability: math,
    inf,
    rus,
    ruso: rus,
    by: rus,
    en,
    de,
    fr,
    sp,
    phys,
    physp: phys,
    physics: phys,
    chem,
    bioc: bio,
    bio,
    geo,
    soc,
    lit,
    rl: lit,
    hist,
    kh: hist,
    bh: hist,
    nat,
    wh
  })

  return subject ? (
    <img
      className={className}
      src={subjectLogoMap[subjectName]}
      alt={`Иллюстрация «${subject.title}»`}
    />
  ) : (
    <Fallback className={cx(className, `${className}_fallback`)} />
  )
}

export default SubjectImage
